<template>
  <div>
    <!-- mobile -->

    <v-navigation-drawer
      v-model="drawer"
      app
      temporary
      color="#F5F6F7"
      overlay-color="#000522"
      overlay-opacity="0.66"
      width="280"
    >
      <!-- src="../assets/img/Drawer.jpg" -->
      <v-container>
        <v-row class="d-flex flex-row align-center" style="padding-top: 18.5px">
          <v-col cols="9">
            <v-img
              src="@/assets/landing/logo_header.svg"
              :max-width="180"
              contain
            />
          </v-col>

          <v-col cols="3" class="d-flex justify-end">
            <v-btn icon @click.stop="drawer = !drawer">
              <v-icon size="30">
                {{ icons.mdiClose }}
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>

      <!-- <v-divider /> -->

      <v-list dense>
        <v-list-item
          v-for="nav in navigations"
          :key="nav.indexGiven"
          class="pl-0 ml-0"
        >
          <v-list-item-content class="" @click="nav.method">
            <v-btn
              text
              color="primary"
              tile
              :small="!$vuetify.breakpoint.lgAndUp"
              class="text-drawer"
              
            >
              {{ $t(nav.navText) }}
            </v-btn></v-list-item-content
          >
        </v-list-item>
        <v-list-item class="">
          <v-list-item-content>
            <v-btn
              v-if="false"
              color="primary"
              @click="handleLocale()"
              style="border-radius: 9px"
              small
              min-height="38"
              width="80"
            >
              <span class="d-flex flex-row justify-center align-center">
                <v-img
                  src="../../../assets/landing/mapa_mundi.svg"
                  max-width="20"
                  max-height="20"
                  width="20"
                  height="20"
                  min-width="20"
                  mix-width="20"
                >
                </v-img>
                <span class="ml-2 text">
                  {{ this.$i18n.locale }}
                </span>
              </span>
            </v-btn>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <!-- desktop -->

    <v-app-bar
      app
      :flat="flat"
      color="#F5F6F7"
      :class="{ 'blur-nav': !flat }"
      height="100"
    >
      <v-container
        class="d-flex flex-row align-center justify-space-between"
        fluid
        :class="{ 'pa-0 ma-0': !$vuetify.breakpoint.lgAndUp }"
      >
        <v-toolbar-title class="d-flex flex-row align-center">
          <v-img
            src="@/assets/landing/logo_header.svg"
            :max-width="$vuetify.breakpoint.lgAndUp ? 260 : 180"
            contain
          />
          <!-- <h6 class="ml-2">Panel corporativo</h6> -->
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <template v-if="$vuetify.breakpoint.lgAndUp">
          <v-btn
            v-for="nav in navigations"
            :key="nav.indexGiven"
            text
            color="primary"
            tile
            :small="!$vuetify.breakpoint.lgAndUp"
            class="text"
            @click="nav.method"
          >
            {{ $t(nav.navText) }}
          </v-btn>
          <v-btn
            v-if="false"
            color="primary"
            @click="handleLocale()"
            style="border-radius: 9px"
            small
            class="ml-2"
            width="80"
            min-height="38"
          >
            <span class="d-flex flex-row justify-center align-center">
              <v-img
                src="../../../assets/landing/mapa_mundi.svg"
                max-width="20"
                max-height="20"
                width="20"
                height="20"
                min-width="20"
                mix-width="20"
              >
              </v-img>
              <span class="ml-2 text">
                <!-- {{ locales.find((l) => l.locale === $i18n.locale).title }} -->
                {{ this.$i18n.locale }}
                <!-- EN -->
              </span>
            </span>
          </v-btn>
        </template>
        <v-app-bar-nav-icon
          v-else
          @click.stop="drawer = !drawer"
        ></v-app-bar-nav-icon>
      </v-container>
    </v-app-bar>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import { setVeeLocale } from "@/utils";
import { loadLanguageAsync } from "@/plugins/i18n";
import { mdiClose } from "@mdi/js";

export default {
  props: {
    flat: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      drawer: false,
      locales: [
        {
          title: "ES",
          locale: "es",
        },
        {
          title: "EN",
          locale: "en",
        },
      ],
      navigations: [
        {
          navText: "About the panel",
          indexGiven: 1,
          id: "about",
          method: () => {
            this.handleNavBarNavigation({ id: "#panel-explained" });
          },
        },
        {
          navText: "Our clients",
          indexGiven: 2,
          id: "clients",
          method: () => {
            this.handleNavBarNavigation({ id: "#business" });
          },
        },
        {
          navText: "Contact",
          indexGiven: 3,
          id: "contact",
          method: () => {
            if (this.$route.name !== "contact") {
              this.$router.push({ name: "contact" });
              return;
            }
            this.$vuetify.goTo("#contact", {
              duration: 250,
            });
          },
        },
        {
          navText: "FAQ2",
          indexGiven: 4,
          id: "faq",
          method: () => {
            this.handleNavBarNavigation({ id: "#faq", offset: -150 });
          },
        },
        {
          navText: this.isLogged ? "Back to the panel" : "Login",
          indexGiven: 5,
          id: "login",
          method: () => {
            this.isLogged
              ? this.$router.push({ name: "dashboards" })
              : this.$router.push({ name: "auth-login" });
          },
        },
      ],
      icons: {
        mdiClose,
      },
    };
  },
  methods: {
    ...mapActions("auth", ["setLang"]),
    handleLocale() {
      let prevLocal = this.$i18n.locale;
      let locale = "";
      switch (prevLocal) {
        case "es":
          locale = "en";
          break;
        case "en":
          locale = "es";
          break;
        default:
          break;
      }
      this.setLang(locale);
      setVeeLocale(locale);
      this.$vuetify.lang.current = locale;
      loadLanguageAsync(locale);
    },
    async handleNavBarNavigation(element) {
      const { id, offset = 0 } = element;

      if (this.$route.name !== "landing") {
        await this.$router.push({ name: "landing" });
        await new Promise((resolve) => setTimeout(resolve, 500));
      }

      this.$vuetify.goTo(id, {
        duration: 250,
        offset: offset,
      });
    },
  },
  computed: {
    ...mapState("auth", ["isLogged"]),
  },
};
</script>
<style lang="scss" scoped>
.v-toolbar {
  transition: 0.6s;
}
.text {
  font-family: Urbanist;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 21.333px;
}
.blur-nav {
  background-color: rgba(
    245,
    246,
    247,
    0.85
  ) !important; /* Set a background color with opacity */
  backdrop-filter: blur(8px);
}

.text-drawer {
  color: #000421;
  font-family: Urbanist;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 29.499px; /* 184.371% */
  text-transform: uppercase;
}

@media (min-width: 960px) and (-moz-osx-font-smoothing: grayscale) and (min--moz-device-pixel-ratio: 1.25),
  (-webkit-font-smoothing: antialiased) and (min-resolution: 120dpi),
  (min-resolution: 1.25dppx) {
  .text {
    font-family: Urbanist;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 21.333px;
  }
}
</style>